<template>
    <div class="grid">
        <div class="col-12">
            <div class="card text-center" v-if="permissionDenied">
                <h4>A kért oldal megtekintéséhez nincs jogosultsága!</h4>
            </div>
            <div class="card" v-if="!permissionDenied">
                <Toast/>
                <Toolbar class="mb-4">
                    <template v-slot:start>
                        <div class="my-2">
                            <Button label="Új" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew"/>
                        </div>
                    </template>
                    <template v-slot:end>
                        <Button label="Export" icon="pi pi-download" class="p-button-help" @click="exportToExcel"/>
                    </template>
                </Toolbar>

                <DataTable ref="dt" :value="generalTerms" v-model:selection="selectedGeneralTerms" dataKey="id"
                           :paginator="true" :rows="10" :loading="loading"
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                           :rowsPerPageOptions="[5,10,25]"
                           currentPageReportTemplate="" responsiveLayout="scroll" filterDisplay="row">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">Általános szerződési feltételek <br> <strong><small>Összesen: {{
                                    total
                                }}</small></strong></h5>
                        </div>
                    </template>

                    <Column selectionMode="multiple" headerStyle="width: 1rem"></Column>
                    <Column field="id" header="#" :sortable="true" headerStyle="width:2%; min-width:5rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">#</span>
                            {{ slotProps.data.id }}
                        </template>
                    </Column>
                    <Column field="name" header="Név" :sortable="true" headerStyle="width:15%; min-width:30rem;"
                            :showFilterMenu="false">
                        <template #body="slotProps">
                            <span class="p-column-title">Név</span>
                            {{ slotProps.data.name }}
                        </template>
                    </Column>
                    <Column field="name" header="Nyelv" :sortable="true" headerStyle="width:10%; min-width:20rem;"
                            :showFilterMenu="false">
                        <template #body="slotProps">
                            <span class="p-column-title">Nyelv</span>
                            {{ slotProps.data.language.name }}
                        </template>
                    </Column>
                    <Column field="createdAt" header="Létrehozás dátuma" :sortable="true"
                            headerStyle="width:15%; min-width:20rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Létrehozás dátuma</span>
                            {{ slotProps.data.createdAt }}
                        </template>
                    </Column>
                    <Column headerStyle="min-width:10rem;">
                        <template #body="slotProps">
                            <Button icon="pi pi-eye" class="p-button-rounded p-button-primary mr-2"
                                    @click="show(slotProps.data)"/>
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger mt-2" @click="confirmDelete(slotProps.data)" />
                        </template>
                    </Column>
                    <template #empty>
                        Nincs egyetlen általános szerződési feltétel sem.
                    </template>
                </DataTable>

                <Dialog v-model:visible="generalTermDialog" :style="{width: '800px'}" header="Általános szerződési feltétel adatai" :modal="true"
                        class="p-fluid">
                    <div class="field col">
                        <label for="inventoryStatus">Nyelv</label>
                        <Dropdown v-model="generalTerm.language" :options="languagesDropdown" optionLabel="label" optionValue="value"
                                  placeholder="Kérlek válassz..." :filter="true" filterBy="label" />
                    </div>

                    <div class="formgrid grid">
                        <div class="field col-2">
                            <label for="firstName">Fájl</label>
                            <FileUpload mode="basic" name="file" :customUpload="true" @uploader="onUpload"
                                        :auto="true"
                                        :maxFileSize="10000000" class="p-button-outlined p-button-plain"
                                        :chooseLabel="file"></FileUpload>
                        </div>
                    </div>

                    <template #footer>
                        <Button label="Mégse" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
                        <Button label="Mentés" icon="pi pi-check" class="p-button-success" @click="save"
                                :loading="loading"/>
                    </template>
                </Dialog>

                <Dialog v-model:visible="showGeneralTermDialog" :style="{width: '800px'}" header="Általános szerződési feltétel adatai"
                        :modal="true" class="p-fluid">
                    <div class="surface-card" v-if="!loading">
                        <ul class="list-none p-0 m-0 border-top-1 border-300">
                            <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                <div class="text-500 w-full md:w-5 font-medium">ID</div>
                                <div class="text-900 w-full md:w-5">{{ generalTerm.id }}</div>
                            </li>
                            <li class="flex align-items-center py-3 px-2 flex-wrap">
                                <div class="text-500 w-full md:w-5 font-medium">Név</div>
                                <div class="text-900 w-full md:w-5">{{ generalTerm.name }}</div>
                            </li>
                            <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                <div class="text-500 w-full md:w-5 font-medium">Fájl</div>
                                <div class="text-900 w-full md:w-5">
                                    <a :href="this.generalTerm.fileName" target="_blank">Fájl megtekintése</a>
                                </div>
                            </li>
                            <li class="flex align-items-center py-3 px-2 flex-wrap">
                                <div class="text-500 w-full md:w-5 font-medium">Létrehozás dátuma</div>
                                <div class="text-900 w-full md:w-5">{{ generalTerm.createdAt }}</div>
                            </li>
                        </ul>
                    </div>
                </Dialog>

                <Dialog v-model:visible="deleteDialog" :style="{width: '550px'}" header="Törlés megerősítése"
                        :modal="true">
                    <div class="flex align-items-center justify-content-center mt-3">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
                        <span>Biztosan törölni szeretné a következő ÁSZF-et: <br> <b>{{ generalTerm.name }}</b>?</span>
                    </div>
                    <template #footer>
                        <Button label="Nem" icon="pi pi-times" class="p-button-text"
                                @click="deleteDialog = false"/>
                        <Button label="Igen" icon="pi pi-check" class="p-button-danger" @click="deleteGeneralTerm"/>
                    </template>
                </Dialog>
            </div>
        </div>
    </div>

</template>

<script>
import ContentService from "@/service/ContentService";
import LanguageService from "@/service/LanguageService";
import * as dayjs from "dayjs";
import ExcelJS from 'exceljs';

export default {
    data() {
        return {
            total: 0,
            generalTerms: null,
            file: '',
            languagesDropdown: [],
            languageId: null,
            generalTermDialog: false,
            showGeneralTermDialog: false,
            generalTerm: {
                language: null
            },
            selectedGeneralTerms: null,
            submitted: false,
            loading: true,
            permissionDenied: true,
            deleteDialog: false,
        }
    },
    contentService: null,
    languageService: null,
    created() {
        this.contentService = new ContentService();
        this.languageService = new LanguageService();
    },
    mounted() {
        let user = JSON.parse(localStorage.getItem('userData'));

        if (user.role === 1) {
            this.permissionDenied = true;
        } else {
            this.permissionDenied = false;
            this.getAll()
            this.getAllLanguage()
        }
    },
    methods: {
        onUpload(event) {
            this.file = this.$options.filters.truncate(event.files[0].name, 30);
            this.createBase64Pdf(event.files[0]);
        },
        createBase64Pdf: function (FileObject) {
            const reader = new FileReader();

            reader.onload = (event) => {
                console.log(event)
                this.generalTerm.file = event.target.result;
            }

            reader.readAsDataURL(FileObject);
        },
        getAll() {
            this.contentService.getAllGeneralTerms().then(response => {
                this.total = response.data.total
                this.generalTerms = response.data.data
                this.loading = false;
            });
        },
        getAllLanguage() {
            this.languageService.getAll().then(response => {
                response.data.data.map((language) => {
                    this.languagesDropdown.push({
                        label: language.name, value: language.id
                    })
                });
            });
        },
        openNew() {
            this.generalTerm = {};
            this.submitted = false;
            this.generalTermDialog = true;
        },
        hideDialog() {
            this.generalTermDialog = false;
            this.submitted = false;
        },
        save() {
            this.submitted = true;
            this.loading = true;

            let generalTerm = {
                languageId: this.generalTerm.language,
                file: this.generalTerm.file,
                type: 'general_term',
            };

            this.contentService.createGeneralTerm(generalTerm).then(() => {
                this.generalTermDialog = false;
                this.generalTerm = {};
                this.file = '';
                this.getAll()

                this.$toast.add({
                    severity: 'success',
                    summary: 'Sikeres művelet!',
                    detail: 'Az általános szerződési feltétel mentése sikeres volt.',
                    life: 5000
                });

                this.loading = false;
            }).catch(e => {
                this.$toast.add({
                    severity: "error",
                    summary: "Sikertelen művelet!",
                    detail: e.response.data.error,
                    life: 6000,
                });

                this.loading = false;
            });
        },
        show(generalTerm) {
            this.generalTerm = {...generalTerm};
            this.showGeneralTermDialog = true;
        },
        async exportToExcel() {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Sheet 1');
            worksheet.columns = [
                { header: '#', key: 'id', width: 10 },
                { header: 'Név', key: 'name', width: 40 },
                { header: 'Nyelv', key: 'language', width: 20 },
                { header: 'Létrehozás dátuma', key: 'createdAt', width: 20 }
            ];

            const selectedProperties = worksheet.columns.map(column => column.key);
            const data = this.generalTerms === null ? [] : this.generalTerms;
            const modifiedData = data.map(obj =>
                selectedProperties.reduce((acc, prop) => {
                acc[prop] = obj[prop];
                if (prop === 'language') {
                    acc[prop] = obj[prop].name;
                }
                return acc;
                }, {})
            );

            modifiedData.forEach(row => {
                worksheet.addRow(Object.values(row));
            });

            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            const today = dayjs().format('YYYY-MM-DD');
            const filename = `${today}_altalanos_szerzodesi_feltetelek.xlsx`;
            a.download = filename;
            a.click();
        },
        confirmDelete(generalTerm) {
            this.generalTerm = generalTerm;
            this.deleteDialog = true;
        },
        deleteGeneralTerm() {
            this.loading = true;

            this.contentService.deleteGeneralTerm(this.generalTerm.id).then(() => {
                this.deleteDialog = false;
                this.generalTerm = {};
                this.getAll()

                this.$toast.add({
                    severity: 'success',
                    summary: 'Sikeres művelet!',
                    detail: 'Az általános szerződési feltétel törlése sikeres volt.',
                    life: 5000
                });

                this.loading = false;
            }).catch(e => {
                this.$toast.add({
                    severity: "error",
                    summary: "Sikertelen művelet!",
                    detail: e.response.data.error,
                    life: 6000,
                });

                this.loading = false;
            });
        },
    },
    filters: {
        truncate: function (text, length) {
            if (text.length > length) {
                return text.substring(0, length) + '...'
            } else {
                return text
            }
        },
    },
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/badges.scss';
</style>
